import React from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import ReCAPTCHA from "react-google-recaptcha";
import '../styles/MyForm.css';
class MyForm extends React.Component {
    constructor(props) {
        super(props);
        this.recaptchaRef = React.createRef();
      }
    render() {
    return (
      <Form  className="my-form" onSubmit={(e) => alert("Thanks for your valuable feedback")}>
        <Form.Group controlId="formBasicName">
          <Form.Label>Name</Form.Label>
          <Form.Control type="text" placeholder="Enter your name" />
        </Form.Group>

        <Form.Group controlId="formBasicMobile">
          <Form.Label>Mobile No</Form.Label>
          <Form.Control type="text" placeholder="Enter your mobile number" />
        </Form.Group>

        <Form.Group controlId="formBasicEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control type="email" placeholder="Enter your email" />
        </Form.Group>

        <Form.Group controlId="formBasicCity">
          <Form.Label>City</Form.Label>
          <Form.Control type="text" placeholder="Enter your city" />
        </Form.Group>

        <Form.Group controlId="formBasicDropdown">
          <Form.Label>Category</Form.Label>
          <Form.Control as="select">
            <option value="sale">Sale</option>
            <option value="support">Support</option>
            <option value="feedback">Feedback</option>
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="formBasicMessage">
          <Form.Label>Message</Form.Label>
          <Form.Control as="textarea" rows={3} />
        </Form.Group>

        {/* <ReCAPTCHA
          ref={this.recaptchaRef}
          sitekey="your_site_key"
        /> */}
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
    );
  }
}

export default MyForm;