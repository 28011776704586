import logo from './logo.jpg';
import './App.css';
import MyForm from './components/MyForm';
function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <MyForm />
        <footer>
          <p style={{ textAlign: 'center' ,fontSize: '12px'}}>Copyright © 80x.co.in | All rights reserved
            Designed & Developed by Mk e-Tech Pvt. Ltd.
          </p>
        </footer>
      </header>

    </div>
  );
}

export default App;
